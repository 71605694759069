import "./style.css"
import subscription from "../../api/subscription";

function SubBlock(){
    async function subscription_handle(payment_type){
        await subscription(payment_type)
    }

    return(
        <div className="SubBlock">
            <div id="subBlock">
                <text id="mounthText">1 Месяц</text>
                <text id='price'>100 ₽</text>
                <p id='buyButton' onClick={() => subscription_handle(1)}>Оплатить</p>
            </div>
            <div id="subBlock">
                <text id="mounthText">6 Месяцев</text>
                <text id='price'>400 ₽</text>
                <p id='buyButton' onClick={() => subscription_handle(2)}>Оплатить</p>
            </div>
            <div id="subBlock">
                <text id="mounthText">12 Месяцев</text>
                <text id='price'>700 ₽</text>
                <p id='buyButton' onClick={() => subscription_handle(3)}>Оплатить</p>
            </div>
        </div>
    )
}

export default SubBlock;