import axios from "axios";

const tg = window.Telegram.WebApp;

async function get_user(){
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const user_id = tg.initDataUnsafe.user ? tg.initDataUnsafe.user.id : 5608669623;
    // const user_id = 5608669623;
    try {
        const response = await axios.get(`${backend_url}/users/me`, {
            headers: {
                "api-key": user_id,
                "ngrok-skip-browser-warning": 1
            }
        });
        return response.data;
    } catch (error) {
        console.error("Network error occurred:", error);
        throw error;
    }
}

export default get_user;