import "./style.css";
const tg = window.Telegram.WebApp;

function Server({ server_key }) {
    const backend_url = process.env.REACT_APP_BACKEND_URL;

    const handleClick = () => {
        const encodedUrl = server_key.access_url;
        const redirectUrl = `${backend_url}/redirect?url=ss://`;
        console.log('Redirecting to:', redirectUrl);

        // Copy the access_url to the clipboard
        navigator.clipboard.writeText(encodedUrl)
            .then(() => {
                console.log('URL copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy URL to clipboard', err);
            });

        tg.openLink(redirectUrl);
    };

    return (
        <div className="Server" onClick={handleClick}>
            <a>{server_key.server}</a>
            <p>Сервер активен</p>
        </div>
    );
}

export default Server;
