import axios from "axios";

const tg = window.Telegram.WebApp;

async function subscription(payment_type){
    const backend_url = process.env.REACT_APP_BACKEND_URL
    const user_id = tg.initDataUnsafe.user? tg.initDataUnsafe.user.id : 5608669623;
    // const user_id = 5608669623;
    tg.close();
    await axios.post(`${backend_url}/users/subscription?payment_type=${payment_type}`, {},  {
        headers: {
            "api-key": user_id,
            "ngrok-skip-browser-warning": 1
        }
    })
        .then(response => {
            return response
        })
}

export default subscription