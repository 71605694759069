import './App.css';
import MainPage from "./pages/MainPage/MainPage";

const tg = window.Telegram.WebApp;

function App() {
    tg.expand();
  return (
    <div className="App">
      <MainPage/>
    </div>
  );
}

export default App;
