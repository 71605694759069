import React from 'react';
import './style.css';

function UserComponent({ user }) {
    return (
        <div className="UserComponent">
            <div className="user-avatar">
                {user.photo_url && <img src={user.photo_url} alt={`${user.first_name} ${user.last_name}`} />}
            </div>
            <div className="user-details">
                <h2>{user.first_name} {user.last_name}</h2>
                {user.username && <p>@{user.username}</p>}
                {user.subscription_status && <p className="premium">Подписка активна до {user.subscription.split(" ")[0]}</p>}
                {!user.subscription_status && <p className="premiuminactive">Подписка неактивна!</p>}
            </div>
        </div>
    );
}

export default UserComponent;
