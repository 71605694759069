import './style.css';

function Loader() {
    return (
        <div className="Loader">
            <div className="spinner"></div>
        </div>
    );
}

export default Loader;
